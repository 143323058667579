import React, { useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import contactmap from '../../Assets/kerala.jpg';
import { FaPhoneVolume } from "react-icons/fa6";
import './style.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_yuku9jo',    // Replace with your EmailJS service ID
            'template_hyntwki',    // Replace with your EmailJS template ID
            form.current,
            '2-hq1Cj1IRFrVebBE'      // Replace with your EmailJS public key
        ).then((result) => {
            console.log(result.text);
            alert("Message Sent Successfully!");
        }, (error) => {
            console.log(error.text);
            alert("Failed to Send Message");
        });
    };

    return (
        <div className='contact-us-root'>
            <div className='contact-form'>
                <Form ref={form} onSubmit={sendEmail}>
                    <Row>
                        <Col className='contact-form-col'>
                            <h5>
                                <span className='first'>{"Get in"}</span>&nbsp;
                                <span className='second'>{"touch"}</span>
                            </h5>
                            <h6>{"Your corporate travel transformation starts here!"}</h6>
                            <Form.Group className="mb-2">
                                <Form.Control name="contactName" placeholder="Contact name" className="contact-input" />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Control name="contactPhone" placeholder="Contact phone" className="contact-input" />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Control name="email" placeholder="Email" className="contact-input" />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Control name="message" placeholder="Your Message" className="contact-input" as="textarea" />
                            </Form.Group>
                            <Button variant="success" type="submit" className="submit-button">
                                {"SUBMIT"}
                            </Button>
                        </Col>
                        <Col className='column'>
                            <div className="background"></div>
                            <img src={contactmap} alt="contact-us" className="image" />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default ContactForm;
