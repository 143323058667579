import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import './style.css';

const ServiceCard = ({ id, heading, serviceimage, shortdescription, buttonName }) => {
    const navigate = useNavigate();

    const routeTOUrl = (id, name) => {
        window.scrollTo(0, 0);
        if (buttonName === "Book Now") {
            const phoneNumber = "7306999099"; // Replace with the desired number

            // Function to detect if the user is on a mobile device
            const isMobile = /Mobi|Android/i.test(navigator.userAgent);

            // URL for WhatsApp
            const whatsappUrl = isMobile
                ? `https://wa.me/${phoneNumber}` // Mobile URL
                : `https://web.whatsapp.com/send?phone=${phoneNumber}`; // Desktop URL

            // Open the URL in a new tab
            window.open(whatsappUrl, '_blank');
        } else {
            localStorage.setItem("serviceid", id);
            navigate(`/services/${name}`);
        }
    };

    return (
        <div className='service-card'>
            <div className="service-image">
                <img src={serviceimage} alt="service-image"  />
            </div>
            <div className='service-content'>
                <h6 className='service-heading'>
                    {heading}
                </h6>
                <p className='service-desc'>
                    {shortdescription}
                </p>
                <div className='service-btn-div'>
                    <Button variant='outline-success'
                        onClick={() => routeTOUrl(id, heading)}
                        className='service-btn'>
                        {buttonName}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
