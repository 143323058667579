import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Headings from '../Headings';
import './style.css';

const About = () => {
    return (
        <div className="main-container">
            <Row className="main-row">
                <Col xs={12} lg={8} md={10} className="left-partition">
                    <Headings color={true} first={"About"} second={"Us"} />
                    <p>
                        {"Welcome to RDTS, where we precisely and flawlessly innovate corporate transportation. Unmatched dependability and flexibility in travel are offered by RDTS, a top supplier of transportation solutions meant solely for corporate use. We recognize that every corporate client has unique needs, and our goal is to exceed those needs by offering a whole new level of service that is tailored for optimal effectiveness and ease."}
                    </p>
                    <p>
                       {"Our top priority is meeting the needs of our clients, which is why we offer a wide selection of automobiles. Large parties and small family vacations may be accommodated by RTDS thanks to its diverse fleet of vehicles, which includes stylish 4-seat cars for short city trips and roomy 17-seat buses for larger groups, thereby guaranteeing complete customer pleasure. They are able to easily and comfortably fit any size of group."}
                    </p>
                    <p>
                        {"Our airport transfer services combine ease and elegance. The trip will be a new experience as we provide roomy, air-conditioned cars, which have been carefully chosen to meet the needs of the client to accommodate both passengers and their personal belongings. Every journey is made enjoyable and memorable by our excellent customer service, which demonstrate our dedication to comfort and efficiency. RDTS specializes in providing best travel experiences, offering a wide range of customized packages that include sightseeing, cultural excursions, and corporate vacations. With our all-inclusive travel packages, you may take advantage of carefully arranged accommodations, transportation, and guided tours."}
                    </p>
                    <p>
                        {"Our goal at RDTS is to enhance your business travel experience by incorporating creativity, dependability, and excellent service. join us, and together with us, lets discover the best side of cooperate commute and elevate your company's over all transportation experience."}
                    </p>
                </Col>
                <Col xs={12} lg={4} md={2} className="right-partition">
                    <Row>
                        <Col xs={12} className="top-child">
                            <h6>{"Mission"}</h6>
                           <p>
                           {"To be the leading provider of corporate car hire solutions, committed to ensuring smooth, pleasant, and convenient transportation for businesses, thereby increasing operational efficiency and customer satisfaction."}
                            </p> 
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="bottom-child">
                            <Row>
                                <Col xs={12} className="bottom-left-child">
                                    <h6>{"Vision"}</h6>
                                   <p>
                                    {"To transform corporate transportation by improving services, growing our fleet, and leveraging technology to provide unrivaled travel experiences for businesses."}
                                   </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default About;
